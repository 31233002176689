'use client';

import { VideoPartialInterface } from '@deardigital/shared/interfaces';
import { isInMobileBreakpointView } from '@deardigital/shared/utilities';
import VideoPlayer from './video-player';

/* eslint-disable-next-line */
export interface VideoPlayerProps {
  videoSm: VideoPartialInterface;
  videoLg?: VideoPartialInterface | null;
}

export function VideoPlayerBase({ videoSm, videoLg }: VideoPlayerProps) {
  const isMobile = isInMobileBreakpointView();

  // Determine which video to display based on the breakpoint
  const videoToDisplay = isMobile ? videoSm : videoLg;

  // Return null if no video is available to display
  if (!videoToDisplay) return null;

  return <VideoPlayer video={videoToDisplay} />;
}

export default VideoPlayerBase;
