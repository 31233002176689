import { Navbar } from 'flowbite-react';
import { GlobalInterface, NavigationItemInterface } from '@deardigital/shared/interfaces';
import NavigationItemDesk from './navigation-item';

export interface NavigationCollapseProps {
  globals: GlobalInterface | null;
  showMenuWithNav: (item: NavigationItemInterface) => boolean;
  isNavOpen: boolean;
  setIsNavOpen: (value: boolean) => void;
}

export function NavigationCollapse({ globals, showMenuWithNav, isNavOpen, setIsNavOpen }: NavigationCollapseProps) {
  return (
    <Navbar.Collapse>
      {globals?.header?.navigation?.map((item, key) => (
        <div key={key}>
          <NavigationItemDesk
            navItem={item}
            showMenuWithNav={showMenuWithNav}
            isNavOpen={isNavOpen}
            setIsNavOpen={setIsNavOpen}
            index={key}
            globals={globals}
          />
        </div>
      ))}
    </Navbar.Collapse>
  );
}
