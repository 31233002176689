import { ICONS } from '@deardigital/shared/constants';
import { GlobalInterface, LinkInterface } from '@deardigital/shared/interfaces';
import { useState } from 'react';
import IconRenderer from '../../icons/icon-renderer.component';
import LinkRenderer from '../../link/link-renderer.component';

export interface NavigationAccordionItemProps {
  section: {
    title: string | null;
    links: LinkInterface[] | null;
  };
  globals: GlobalInterface;
}

export function NavigationAccordionItem({ section, globals }: NavigationAccordionItemProps) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  let isRotate = 'rotate-0';
  let isHidden = 'hidden';

  if (isAccordionOpen) {
    isRotate = 'rotate-45';
    isHidden = 'block';
  }

  function handleAccordionToggle() {
    setIsAccordionOpen(!isAccordionOpen);
  }

  return (
    <div className="accordion-wrapper">
      <div className={`accordion border-0 border-b border-b-blue-50`}>
        <div className={`text-lg py-5 font-medium ${globals.theme?.color.text}`} onClick={handleAccordionToggle}>
          <div className="flex justify-between items-center">
            {section.title}
            <span className={`accordion-symbol flex items-center transition-all duration-300 ${isRotate}`}>
              <IconRenderer className={`w-4 h-4 ${globals.theme?.color.text}`} icon={ICONS.plusIcon} />
            </span>
          </div>
        </div>
        <div className={`accordion-panel p-0 transition-all duration-300 ${isHidden}`}>
          <ul>
            {section?.links &&
              section?.links?.map((link, index) => (
                <li className="mb-5" key={index}>
                  <LinkRenderer className={globals.theme?.color.text} size='text-sm' {...link} />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
