import { BlogCardInterface } from '@deardigital/shared/interfaces';
import { ColorShade } from '@deardigital/shared/constants';
import Link from 'next/link';
import ImageRenderer from '../image-renderer/image-renderer';

/* eslint-disable-next-line */
export interface BlogCardProps extends BlogCardInterface {
  theme: ColorShade;
}

export function BlogCard({ thumbnail, title, slug, authors, theme }: BlogCardProps) {
  return (
    <div className='relative p-2 pt-0'>
      {thumbnail && <ImageRenderer className="w-full rounded-xl object-cover" image={thumbnail} />}
      <div className='mt-4'>
        {title && (
          <h4 className={`${theme.color.heading} font-sans text-base font-medium`}>
            <Link className='after:absolute after:inset-0' href={`/${slug}`}>
              {title}
            </Link>
          </h4>
        )}
        {authors && (
          <div className="pt-2 mt-auto">
            {authors.map((author, key) => (
              <span key={key} className={`${theme.color.text} text-sm font-medium font-sans`}>{author.name}</span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default BlogCard;
