import { GlobalInterface, NavigationItemInterface } from '@deardigital/shared/interfaces';
import { NavigationAccordionItem } from './navigation-accordion';

export interface NavigationSubMenuAccordionProps {
  item: NavigationItemInterface;
  globals: GlobalInterface;
}

export function NavigationSubMenuAccordion({ item, globals }: NavigationSubMenuAccordionProps) {
  return (
    <div className="block">
      {item?.cta && <NavigationAccordionItem globals={globals} section={item.cta} key={10} />}

      {item?.links && item?.links?.map((section, index) => <NavigationAccordionItem globals={globals} section={section} key={index} />)}
    </div>
  );
}
