'use client';

import { VideoPartialInterface } from '@deardigital/shared/interfaces';
import dynamic from 'next/dynamic';

// Dynamically import ReactPlayer for file playback
const ReactPlayerFile = dynamic(() => import('react-player/file'), { ssr: false });

// Dynamically import ReactPlayer for lazy loading
const ReactPlayerLazy = dynamic(() => import('react-player/lazy'), { ssr: false });

/* eslint-disable-next-line */
export interface VideoPlayerProps {
  video: VideoPartialInterface;
  overlay?: boolean;
  posterLoadingState?: 'lazy' | 'eager';
}

export function VideoPlayer({ video }: VideoPlayerProps) {
  // Determine the type of player to use based on conditions, such as video source type
  const hasYoutubeUrl = video.youtube;
  const hasExternalUrl = video.url;

  if (!video) return null;

  let videoUrl = video.asset?.url;
  if (hasYoutubeUrl && video.youtube) {
    videoUrl = video.youtube;
  } else if (hasExternalUrl) {
    videoUrl = hasExternalUrl;
  }

  // Determine the type of player to use based on conditions, such as video source type
  const ReactPlayerComponent = hasYoutubeUrl || hasExternalUrl ? ReactPlayerLazy : ReactPlayerFile;

  return (
    <div className="h-full w-full">
      <ReactPlayerComponent
        url={videoUrl}
        width="100%"
        height="100%"
        playing={true}
        loop={true}
        playsinline={true}
        muted={true}
        className={`flex min-h-full w-full items-center`}
      />
    </div>
  );
}

export default VideoPlayer;
