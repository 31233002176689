import { ICONS } from '@deardigital/shared/constants';
import { GlobalInterface, NavigationItemInterface } from '@deardigital/shared/interfaces';
import { useState } from 'react';
import IconRenderer from '../../icons/icon-renderer.component';
import { NavigationItemCardsGrid } from './navigation-cards-grid';
import { NavigationSubMenuAccordion } from './navigation-submenu-accordion';

export interface NavigationItemSubMenuProps {
  item: NavigationItemInterface;
  showMenuWithNav: (item: NavigationItemInterface) => boolean;
  globals: GlobalInterface;
}

export function NavigationItemSubMenu({ item, showMenuWithNav, globals }: NavigationItemSubMenuProps) {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  let subMenuContentCSS = 'translate-x-full invisible';

  if (isSubMenuOpen) {
    subMenuContentCSS = 'translate-x-0 visible';
  }

  return (
    <div className="nav-submenu-wrapper">
      <button
        className={`${globals.theme?.color.text} flex items-center justify-between w-full`}
        onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}
      >
        <div className={`${globals.theme?.color.text} text-2xl`}>{item.link?.label}</div>
        <div
          className={`flex justify-center items-center w-12 h-12 rounded-full border ${globals.theme?.button?.secondary.outlineDefault}`}
        >
          <IconRenderer icon={ICONS.arrowRight} className="flex w-3 h-3" />
        </div>
      </button>
      <div
        className={`absolute top-0 left-0 pt-6 pb-10 px-5 w-full h-full ${globals.theme?.background} z-10 flex flex-col gap-5 overflow-x-hidden overflow-y-auto transition-all ${subMenuContentCSS}`}
      >
        <button className="flex items-center gap-4 w-full" onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}>
          <div
            className={`flex justify-center items-center w-12 h-12 rounded-full border ${globals.theme?.button?.secondary.outlineDefault}`}
          >
            <IconRenderer icon={ICONS.arrowLeft} className={`${globals.theme?.color.text} flex w-3 h-3`} />
          </div>
          <div className={`${globals.theme?.color.text} text-2xl`}>{item.link?.label}</div>
        </button>

        <NavigationSubMenuAccordion globals={globals} item={item} />

        {showMenuWithNav(item) && <NavigationItemCardsGrid globals={globals} itemData={item} />}
      </div>
    </div>
  );
}
