'use client';

import { GlobalInterface, NavigationItemInterface } from '@deardigital/shared/interfaces';
import { CustomFlowbiteTheme, Flowbite, Navbar } from 'flowbite-react';
import { useState } from 'react';
import { ReactComponent as Logo } from '../icons/dear-digital-logo.svg';
import LinkRenderer from '../link/link-renderer.component';
import { NavbarToggler } from './navigation/navbar-toggler';
import { NavigationCollapse } from './navigation/navigation-collapse';
import { NavigationListMobile } from './navigation/navigation-list-mobile';

/* eslint-disable-next-line */
export interface HeaderProps {
  globals: GlobalInterface | null;
  hasHero: boolean;
}

const headerTheme: CustomFlowbiteTheme = {
  navbar: {
    root: {
      base: 'rb',
      inner: {
        base: 'ib mx-auto relative flex flex-wrap items-center justify-between z-40 px-4 lg:px-6 py-4',
      },
    },
    collapse: {
      base: 'cb basis-full mb-0 lg:mb-0 navbar-collapse mt-10 lg:mt-0 lg:basis-0 grow w-full flex-wrap lg:ml-auto lg:inline-block lg:w-auto',
      list: 'cl flex h-full lg:items-center flex-col lg:flex-row lg:ml-auto lg:justify-center lg:mt-0 space-y-8 lg:space-x-10 lg:space-y-0 overflow-hidden',
    },
    link: {
      base: 'text-3xl lg:text-base block py-1.5 lg:py-3',
      active: {
        on: 'text-blue-1000 font-medium',
        off: 'text-blue-1000 lg:hover:text-blue-700 font-medium',
      },
    },
    toggle: {
      base: 'lg:hidden',
    },
  },
};

export function Header({ globals, hasHero }: HeaderProps) {
  const positionClassName = hasHero ? 'fixed' : 'relative';

  const [isNavOpen, setIsNavOpen] = useState(false);
  let showNavigationBackground = '';

  if (!isNavOpen) {
    showNavigationBackground = 'hidden';
  }

  // const pathname = usePathname();
  function isActivePage(link: string | null | undefined) {
    return false;
    // return pathname.indexOf(link) > -1;
  }

  const showMenuWithNav = (item: NavigationItemInterface) => {
    if (item?.cards && item?.link) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Flowbite theme={{ theme: headerTheme }}>
      <header className={`${positionClassName} z-40 w-full`}>
        <div className="w-auto px-5 xl:max-w-7xl xl:mx-auto">
          <Navbar fluid className={`z-40 mt-4 lg:mt-10 rounded-button ${globals?.theme?.background} shadow-xl`}>
            <div className="w-full flex justify-between lg:w-auto lg:inline-block">
              <Navbar.Brand href="/">
                <Logo className={`h-8 md:h-10 ${globals?.theme?.color.text}`} />
                <span className="sr-only">dear digital</span>
              </Navbar.Brand>
              <NavbarToggler globals={globals} />
            </div>
            <NavigationCollapse
              globals={globals}
              showMenuWithNav={showMenuWithNav}
              isNavOpen={isNavOpen}
              setIsNavOpen={setIsNavOpen}
            />
            <div className="hidden lg:block">
              {globals?.header?.cta && <LinkRenderer theme={globals.theme} {...globals.header.cta} className="mr-3 md:mr-0" />}
            </div>
          </Navbar>
          <div
            className={`hamburger-menu pt-6 pb-10 px-5 absolute top-auto left-0 -translate-x-full w-full ${globals?.theme?.background} z-10 flex flex-col justify-between overflow-y-auto overflow-x-hidden transition-all lg:hidden`}
          >
            <NavigationListMobile globals={globals} showMenuWithNav={showMenuWithNav} isActivePage={isActivePage} />
            <div className="block w-full">
              {globals?.header?.cta && <LinkRenderer theme={globals.theme} {...globals.header.cta} className="w-full !justify-center" />}
            </div>
          </div>
        </div>
      </header>
      <div
        onClick={() => setIsNavOpen(!isNavOpen)}
        className={`fixed w-full h-full top-0 left-0 z-10 cursor-pointer pointer-events-auto ${showNavigationBackground}`}
      ></div>
    </Flowbite>
  );
}

export default Header;
