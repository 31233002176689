import { CollapseItemInterface, SectionInterface } from '@deardigital/shared/interfaces';
import { Accordion, CustomFlowbiteTheme, Flowbite } from 'flowbite-react';
import LinkRenderer from '../../link/link-renderer.component';

export interface CollapseItemsProps {
  items: CollapseItemInterface[];
  section: SectionInterface;
}

export function Collapse({ items, section }: CollapseItemsProps) {
  const customTheme: CustomFlowbiteTheme = {
    accordion: {
      root: {
        base: `divide-y border-2 divide-red-300 ${section.theme.color.divide}`,
      },
    }
  };

  return (
    <Flowbite theme={{ theme: customTheme }}>
      <Accordion collapseAll className="lg:w-1/2 border-0 bg-transparent">
        {items.map((item, index) => (
          <Accordion.Panel key={index} className="pt-5 border-b-2 border-yellow-500">
            <Accordion.Title
              className={`text-2xl lg:text-3xl mx-0 px-0 py-7 font-serif ${section.theme.color.heading} focus:ring-0 border-1 ${section.theme.color.divide} bg-transparent hover:bg-transparent`}
            >
              {item.title}
            </Accordion.Title>
            <Accordion.Content className="font-sans text-base mx-0 px-0">
              <p className={`mb-2 ${section.theme.color.text}`}>{item.description}</p>
              {item.links?.map((link, index) => (
                <div key={index} className="mt-5">
                  <LinkRenderer {...link} section={section} />
                </div>
              ))}
            </Accordion.Content>
          </Accordion.Panel>
        ))}
      </Accordion>
    </Flowbite>
  );
}
