import { GlobalInterface, MediaInterface, NavigationItemInterface } from '@deardigital/shared/interfaces';
import LinkRenderer from '../link/link-renderer.component';
import { NavigationItemCardsGrid } from './navigation/navigation-cards-grid';

export interface MenuItemProps {
  itemData: NavigationItemInterface | null;
  globals: GlobalInterface;
}

export function MenuItem({ itemData, globals }: MenuItemProps) {
  if (!itemData) {
    return null;
  }

  return (
    <div className={`absolute top-24 left-0 z-10 w-full ${globals.theme?.background} rounded-button p-6 shadow-xl`}>
      <div>
        <div className={`flex justify-between ${itemData.cta?.links ? 'mb-10' : ''}`}>
          {itemData?.cta && (
            <div>
              <h3 className={`text-lg font-medium ${globals.theme?.color.text} mb-4`}>{itemData.cta.title}</h3>
              <ul>
                {itemData.cta.links?.map((link, index) => (
                  <li className="mb-4" key={index}>
                    <LinkRenderer className={globals.theme?.color.text} size="text-md" key={index} {...link} />
                  </li>
                ))}
              </ul>
            </div>
          )}

          {itemData?.links?.map((section, index) => (
            <div key={index}>
              <h3 className={`text-lg font-medium ${globals.theme?.color.text} mb-4`}>{section.title}</h3>
              <ul>
                {section.links.map((link, index) => (
                  <li className="mb-3" key={index}>
                    <LinkRenderer className={globals.theme?.color.text} size="text-md" key={index} {...link} />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        {itemData?.cards?.length && <NavigationItemCardsGrid itemData={itemData} globals={globals} />}
      </div>
    </div>
  );
}
