import { PodcastCardInterface } from '@deardigital/shared/interfaces';
import { ColorShade } from '@deardigital/shared/constants';
import isEmpty from 'lodash.isempty';
import Link from 'next/link';
import ImageRenderer from '../image-renderer/image-renderer';

/* eslint-disable-next-line */
export interface PodcastCardProps extends PodcastCardInterface {
  theme: ColorShade;
}

export function PodcastCard({ thumbnail, title, slug, guests, theme }: PodcastCardProps) {
  return (
    <div className='relative rounded-xl p-2 pt-0'>
      {thumbnail && <ImageRenderer className="w-full rounded-lg object-cover" image={thumbnail} />}
      <div className='mt-4'>
        {title && (
          <h4 className={`${theme.color.heading} font-sans text-base font-medium`}>
            <Link href={`/${slug}`} className="after:absolute after:inset-0">
              {title}
            </Link>
          </h4>
        )}
        {guests && !isEmpty(guests) && (
          <div className="pt-2 mt-auto">
            {guests.map((guest, key) => (
              <span key={key} className={`${theme.color.text} text-sm font-medium font-sans`}>{guest.name}</span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default PodcastCard;
