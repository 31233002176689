'use client';

import { BREAKPOINTS } from '@deardigital/shared/constants';
import { ContentImageInterface } from '@deardigital/shared/interfaces';
import { storyblokEditable } from '@storyblok/react';
import { motion, useMotionValueEvent, useScroll, useSpring } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import SectionBadge from '../badge/section-badge/section-badge';
import ImageRenderer from '../image-renderer/image-renderer';
import Section from '../section/section';
import isEmpty from 'lodash.isempty';

/* eslint-disable-next-line */
export interface ContentImageProps extends ContentImageInterface {}

// this method is used to calculate the scale of the image
// based on the current window width and the breakpoints
const calculateScale = (): number => {
  const windowWidth = window.innerWidth;

  return windowWidth >= BREAKPOINTS.lg
    ? windowWidth / BREAKPOINTS.lg
    : windowWidth >= BREAKPOINTS.md
      ? windowWidth / BREAKPOINTS.md
      : windowWidth / BREAKPOINTS.sm;
};

export function ContentImage(props: ContentImageProps) {
  const [scale, setScale] = useState(1);
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['center center', 'end end'],
  });

  const springScrollY = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  /**
   * initialScale is the initial scale value you want (e.g., 1.2).
   * scrollYProgress is the progress of the scroll, ranging from 0 to 1.
   * scaleChange is the amount the scale changes as scrollYProgress goes from 0 to 1. This is the difference between the initial scale and the final scale.
   * newScale = initialScale - scrollYProgress * scaleChange
   */
  useMotionValueEvent(springScrollY, 'change', (latest) => {
    const containerScale = calculateScale();
    const scrollYProgress = latest;
    const scaleChange = containerScale - 1;
    const calculatedScale = Math.max(1, containerScale - scrollYProgress * scaleChange);

    if (!props.scaleAnimation) return;
    setScale(calculatedScale);
  });

  useEffect(() => {
    if (!props.scaleAnimation) return;
    setScale(calculateScale());
  }, []);

  return (
    <Section {...props.section}>
      <div className="relative overflow-hidden">
        <div>
          <div
            ref={ref}
            className={props.fullWidth ? 'px-5' : 'max-w-screen-xl mx-auto px-5'}
            {...(props._editable ? { ...storyblokEditable(props as any) } : {})}
          >
            {!isEmpty(props.section?.badge) && props.section?.badge && (
              <div className={`${props.section?.spacing || ''} !pb-4`}>
                {props.section.badge.map(
                  (item, key) => item && <SectionBadge key={key} {...item} {...props.section} />,
                )}
              </div>
            )}
            <motion.div style={{ transform: `scale(${scale}, ${scale})` }}>
              <ImageRenderer image={props.image} className="w-full" />
            </motion.div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default ContentImage;
