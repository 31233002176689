'use client';

import { ICONS } from '@deardigital/shared/constants';
import { useState } from 'react';
import IconRenderer from '../../icons/icon-renderer.component';
import { Navbar } from 'flowbite-react';
import { GlobalInterface } from '@deardigital/shared/interfaces';

export interface NavbarTogglerProps {
  globals: GlobalInterface | null;
}

export function NavbarToggler({ globals }: NavbarTogglerProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let navToggleIcon = <IconRenderer icon={isMenuOpen ? ICONS.closeIcon : ICONS.navbarToggle} className="w-6 h-6" />;

  function toggleMobileMenu() {
    const navbar = document.querySelector('nav');
    const header = document.querySelector('header');
    const hamburgerMenu = document.querySelector('.hamburger-menu');

    hamburgerMenu?.classList.toggle('-translate-x-full');
    hamburgerMenu?.classList.toggle('translate-x-0');
    hamburgerMenu?.classList.toggle('active');
    header?.classList.toggle(globals?.theme?.background || '');
    navbar?.classList.toggle('rounded-button');
    navbar?.classList.toggle('mt-4');
    navbar?.classList.toggle('pt-4');
    navbar?.classList.toggle('shadow-xl');
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <div>
      <Navbar.Toggle
        barIcon={() => navToggleIcon}
        aria-expanded="false"
        className={`${globals?.theme?.color.text} w-10 h-10 flex justify-center items-center transition-all duration-300`}
        onClick={toggleMobileMenu}
      />
    </div>
  );
}
